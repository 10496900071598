import i18n from '@/i18n'
import Store from '@/store'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import ReportHeading from '@/Utils/report-head-lic-o'
import { dateFormat, mobileNumber } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, orgID, reportTitle, columns, resultData, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, '/configuration/report-heading/detail', orgID)
        const pdfContent = [
          {
            columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const allRowsHead = []
        let dateW = vm.$t('globalTrans.all')
        if (vm.searchHeader.from_date.from_date && vm.searchHeader.from_date.to_date) {
          dateW = dateFormat(vm.searchHeader.from_date.from_date) + ' ' + vm.$t('globalTrans.from') + ' ' + dateFormat(vm.searchHeader.from_date.to_date)
        }
        let statusW = vm.$t('globalTrans.all')
        if (vm.searchHeader.status) {
          statusW = vm.statusFind(vm.searchHeader.status)
        }
        if (vm.searchHeader.from_date.service_id === 17) {
          const rowItem = [
            { text: vm.$t('org_pro_division.division'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'left' },
            { text: vm.search.division_id ? vm.getColumnName(Store.state.commonObj.divisionList, 'value', vm.searchHeader.division_id) : vm.$t('globalTrans.all'), style: 'td', alignment: 'left' },
            { text: vm.$t('org_pro_district.district'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'left' },
            { text: vm.search.district_id ? vm.getColumnName(Store.state.commonObj.districtList, 'value', vm.searchHeader.district_id) : vm.$t('globalTrans.all'), style: 'td', alignment: 'left' },
            { text: vm.$t('org_pro_upazilla.upazilla'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'left' },
            { text: vm.search.upazilla_id ? vm.getColumnName(Store.state.commonObj.upazilaList, 'value', vm.searchHeader.upazilla_id) : vm.$t('globalTrans.all'), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
          const rowItem2 = [
            { text: vm.$t('li_step.company_type'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'left' },
            { text: vm.search.company_t_2857 ? vm.getColumnName(vm.companyType, 'value', vm.searchHeader.company_t_2857) : vm.$t('globalTrans.all'), style: 'td', alignment: 'left' },
            { text: vm.$t('li_step.type_of_work'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'left' },
            { text: vm.search.type_of_w_8863 ? vm.getColumnName(vm.typeOfWork, 'value', vm.searchHeader.type_of_w_8863) : vm.$t('globalTrans.all'), style: 'td', alignment: 'left' },
            { text: vm.$t('globalTrans.license_no'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'left' },
            { text: vm.search.license_no ? vm.search.license_no : vm.$t('globalTrans.all'), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem2)
          const rowItem1 = [
            { text: vm.$t('globalTrans.company_name'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'left' },
            { text: vm.search.company_name ? vm.search.company_name : vm.$t('globalTrans.all'), style: 'td', alignment: 'left' },
            { text: vm.$t('globalTrans.from_date') + vm.$t('globalTrans.and ') + vm.$t('globalTrans.to_date'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'left' },
            { text: dateW, style: 'td', alignment: 'left' },
            { text: vm.$t('globalTrans.status'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'left' },
            { text: statusW, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem1)
          pdfContent.push({
            table: {
              widths: ['11%', '1%', '15%', '11%', '1%', '15%', '11%', '1%', '15%'],
              body: allRowsHead
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          })
        }
        const allRows = []
        const dynmicColumns = []
        columns.forEach(item => {
          if (item !== undefined && item.key !== 'action') {
            dynmicColumns.push(
              { text: item.label !== undefined ? item.label : '', style: 'th', alignment: 'left' }
            )
          }
        })
        allRows.push(dynmicColumns)
        let index = 1
        resultData.forEach(dataItem => {
          const data = []
          columns.forEach(columnItem => {
            if (columnItem !== undefined) {
              if (columnItem.key === 'index') {
                data.push(
                  { text: vm.$n(index++), style: 'td', alignment: 'left' }
                )
              } else if (columnItem.key === 'application_id') {
                data.push(
                  { text: vm.$n(dataItem[columnItem.key], { useGrouping: false }), style: 'td', alignment: 'left' }
                )
              } else if (columnItem.key === 'mobile_no____5100') {
                data.push(
                  { text: mobileNumber(dataItem[columnItem.key], { useGrouping: false }), style: 'td', alignment: 'left' }
                )
              } else if (columnItem.key === 'nid_no____6440') {
                data.push(
                  { text: vm.$n(dataItem[columnItem.key], { useGrouping: false }), style: 'td', alignment: 'left' }
                )
              } else if (columnItem.key === 'generate_id') {
                let gentr = ''
                if (dataItem[columnItem.key] !== null) {
                  if (parseInt(dataItem[columnItem.key]) !== 0) {
                    gentr = vm.$n(dataItem[columnItem.key], { useGrouping: false })
                  }
                }
                data.push(
                  { text: gentr, style: 'td', alignment: 'left' }
                )
              } else if (columnItem.key === 'status') {
                data.push(
                  { text: vm.getStepName(dataItem.current_step) + ' ' + vm.ServiceLastStepCheck(dataItem.service_id, dataItem.current_step, dataItem.status, dataItem.generate_id, dataItem.doc_verified, dataItem.type), style: 'td', alignment: 'left' }
                )
              } else if (columnItem.key === 'expire_date' || columnItem.key === 'issue_date') {
                let dateData = ''
                if (dataItem[columnItem.key]) {
                  dateData = dateFormat(dataItem[columnItem.key])
                }
                data.push(
                  { text: columnItem.label !== undefined ? dateData : '', style: 'td', alignment: 'left' }
                )
              } else {
                if (columnItem.key !== 'action') {
                  data.push(
                    { text: columnItem.label !== undefined ? dataItem[columnItem.key] : '', style: 'td', alignment: 'left' }
                  )
                }
              }
            }
          })
          if (data.length) {
            allRows.push(data)
          }
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: '*',
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A3',
        pageOrientation: 'Landcape',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          th1: {
            fontSize: (i18n === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          td1: {
            fontSize: (i18n === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          header: {
              fontSize: 14,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          fertilizer: {
            fontSize: 10,
            margin: [10, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 4]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('report-pdf')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
