<template>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ ($i18n.locale === 'bn') ? menuInfoList.search_title_bn : menuInfoList.search_title_en }}</h4>
      </template>
      <template v-slot:body>
          <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
              <b-form  @submit.prevent="handleSubmit(searchSubmit)" >
                <b-overlay :show="unitLoad">
                  <b-row>
                    <b-col lg="4" sm="4">
                          <ValidationProvider name="Organization" vid="org_id" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="org_id"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('user_role.organization')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="gridSearch.org_id"
                                      :options="orgList"
                                      id="org_id"
                                      :disabled="is_disable"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                      </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="4">
                        <ValidationProvider name="Service Name" vid="service_id" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="service_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('org_pro.service_namel')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    v-model="gridSearch.service_id"
                                    :options="serviceNamesList"
                                    id="service_id"
                                    :disabled="is_disable"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="4">
                        <ValidationProvider name="Status" vid="status_id">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="status_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('globalTrans.status')}}
                                </template>
                                <b-form-select
                                  v-model="gridSearch.status"
                                  :options="statusList"
                                  id="status_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{ $t('globalTrans.all') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col v-for="(item, dindex) in layout" :key="dindex" lg="4" md="4" sm="4">
                        <div v-if="item.dataType === 'InputField'">
                          <Input :tmpData="formInputData" :fieldData="customField" :index="dindex" />
                        </div>
                        <div v-if="item.dataType === 'Dropdown'">
                          <Dropdown :fieldData="customField" :data="formInputData[dindex]" :drpChangeData="formInputData[dindex + 1]" :nextIndex="dindex + 1" @updateDropdownData="updateDropdown" :key="componentKey" v-if="item.dataType === 'Dropdown'"/>
                        </div>
                        <div v-if="item.dataType === 'Checkbox'">
                          <Checkbox :fieldData="customField" :data="formInputData[dindex]"/>
                        </div>
                    </b-col>
                    <FromDate v-if="dateRange" :fieldData="customField" @loadValue="setValueInData"/>
                    <ToDate v-if="dateRange" :fieldData="customField" @loadValue="setValueInData"/>
                    <AppId v-if="appId" :fieldData="customField" @loadValue="setValueInData" />
                  </b-row>
                  <b-row v-if="gridSearch.service_id === 17">
                    <b-col lg="4" sm="4">
                      <ValidationProvider name="License No" vid="license_no">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="license_no"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('globalTrans.license_no')}}
                              </template>
                              <b-form-input
                              id="license_no"
                              v-model="gridSearch.license_no"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="4">
                      <ValidationProvider name="CompanyName" vid="company_name">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="company_name"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('globalTrans.company_name')}}
                              </template>
                              <b-form-input
                              id="company_name"
                              v-model="gridSearch.company_name"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="4">
                      <ValidationProvider name="Division" vid="division_id">
                          <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="division_id"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                              {{ $t('org_pro_division.division')}}
                              </template>
                              <b-form-select
                              plain
                              v-model="gridSearch.division_id"
                              :options="divisionList"
                              id="division_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="4">
                      <ValidationProvider name="District" vid="district_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="district_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('org_pro_district.district')}}
                            </template>
                            <b-form-select
                            plain
                            v-model="gridSearch.district_id"
                            :options="districtList"
                            id="district_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{ $t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="4">
                      <ValidationProvider name="Upazila" vid="upazila_id">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="upazila_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('org_pro_upazilla.upazilla')}}
                            </template>
                            <b-form-select
                            plain
                            v-model="gridSearch.upazilla_id"
                            :options="upazilaList"
                            id="upazila_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="4">
                      <ValidationProvider name="Company Type" vid="company_type">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="company_type"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('li_step.company_type')}}
                            </template>
                            <b-form-select
                            plain
                            v-model="gridSearch.company_t_2857"
                            :options="companyType"
                            id="company_type"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="4" sm="4">
                      <ValidationProvider name="Type Of Work" vid="type_of_work">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="type_of_work"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('li_step.type_of_work')}}
                            </template>
                            <b-form-select
                            plain
                            v-model="gridSearch.type_of_w_8863"
                            :options="typeOfWork"
                            id="type_of_work"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                     <b-col lg="4" md="4" sm="4">
                        <ValidationProvider name="Application Type">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="application_type"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{ $t('globalTrans.type') }}
                            </template>
                            <b-form-select
                              plain
                              v-model="gridSearch.type"
                              :options="applicationTypeList"
                              id="application_type"
                              :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">Search</b-button>
                    </div>
                  </div>
                </b-overlay>
              </b-form>
            </ValidationObserver>
      </template>
    </iq-card>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import Input from '@/components/license/admin/search/input-field'
import Dropdown from '@/components/license/admin/search/dropdown-field'
import Checkbox from '@/components/license/admin/search/checkbox-field'
import FromDate from '@/components/license/admin/search/from-date'
import ToDate from '@/components/license/admin/search/to-date'
import AppId from '@/components/license/admin/search/application-id'
import { wordsToSnake } from '@/Utils/fliter'
import { reportSearchView } from '../api/routes'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
export default {
  props: ['menuId'],
  components: {
    Input,
    Dropdown,
    Checkbox,
    FromDate,
    ToDate,
    AppId,
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
        gridSearch: {
            service_id: 0,
            org_id: 0,
            from_date: '',
            to_date: '',
            status: 0,
            license_no: '',
            company_name: '',
            division_id: 0,
            district_id: 0,
            upazilla_id: 0,
            application_id: ''
        },
        layout: [],
        formInputData: [],
        unitLoad: false,
        dateRange: false,
        appId: false,
        serviceNamesList: [],
        customField: [],
        is_disable: false,
        componentKey: 0,
        districtList: [],
        upazilaList: [],
        commonData: {}
    }
  },
  created () {
    this.checkSingleService()
  },
  computed: {
    applicationTypeList () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'New' : 'নতুন', text_en: 'New', text_bn: 'নতুন' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Reneiw' : 'রিনিউ', text_en: 'Reneiw', text_bn: 'রিনিউ' }
      ]
      return list
    },
    paymentTypeList () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'অনলাইন' : 'Online',
          text_en: 'Online',
          text_bn: 'অনলাইন'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'অফলাইন' : 'Offline',
          text_en: 'Online',
          text_bn: 'অনলাইন'
        }
      ]
    },
    statusList () {
      return [
        { value: 3, text: this.$i18n.locale === 'en' ? 'Processing' : 'প্রক্রিয়াধীন' },
        { value: 1, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদিত' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Rejected' : 'প্রত্যাখ্যাত' }
      ]
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    companyType () {
      return [
        { value: 'Individual ownership', text: this.$i18n.locale === 'en' ? 'Individual ownership' : 'ব্যক্তি মালিকানা' },
        { value: 'Joint ownership', text: this.$i18n.locale === 'en' ? 'Joint ownership' : 'যৌথ মালিকানা' },
        { value: 'Private', text: this.$i18n.locale === 'en' ? 'Private' : 'প্রাইভেট' },
        { value: 'Government', text: this.$i18n.locale === 'en' ? 'Government' : 'সরকারি' },
        { value: 'Multinational', text: this.$i18n.locale === 'en' ? 'Multinational' : 'বহুজাতিক' },
        { value: 'NGO', text: this.$i18n.locale === 'en' ? 'Individual ownership' : 'এনজিও' }
      ]
    },
    typeOfWork () {
      return [
        { value: 'Seed production', text: this.$i18n.locale === 'en' ? 'Seed production' : 'বীজ উৎপাদন' },
        { value: 'Seed Sale', text: this.$i18n.locale === 'en' ? 'Seed Sale' : 'বীজ বিক্রি' },
        { value: 'Seeds Processing and Packaging', text: this.$i18n.locale === 'en' ? 'Seeds Processing and Packaging' : 'বীজ প্রক্রিয়াজাত ও প্যাকেটজাত' },
        { value: 'Seed Import', text: this.$i18n.locale === 'en' ? 'Seed Import' : 'বীজ আমদানি' },
        { value: 'Seed Export', text: this.$i18n.locale === 'en' ? 'Seed Export' : 'বীজ রপ্তানি' },
        { value: 'Seed Research and Development', text: this.$i18n.locale === 'en' ? 'Seed Research and Development' : 'বীজ গবেষণা ও উন্নয়ন' }
      ]
    },
    orgList () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    menuInfoList () {
      const menuId = this.menuId
      return this.$store.state.licenseRegistration.commonObj.reportMenuList.find(item => item.menu_id === parseInt(menuId))
    }
  },
  watch: {
    'gridSearch.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'gridSearch.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'gridSearch.org_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.serviceNamesList = this.getServiceList(newVal)
      }
    },
    'gridSearch.service_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadSearchForm(newVal)
      }
    },
    menuId: function (newVal) {
      if (newVal) {
        this.checkSingleService()
        this.layout = []
          this.formInputData = []
          this.dateRange = false
          this.appId = false
        }
    }
  },
  methods: {
     getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getDistrictList (divisionId = null) {
      let districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        districtList = districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    loadSearchForm (serviceId) {
        this.unitLoad = true
        const formData = {
            service_id: serviceId,
            report_process_id: this.menuInfoList.report_process_id
        }
        RestApi.getData(licenseRegistrationServiceBaseUrl, reportSearchView, formData).then(response => {
          if (response.success) {
            this.customField = response.search_field
            this.commonData = response.commonData
            this.setFieldData(response.data)
            this.checkDateRange(response.search_field)
        } else {
          this.layout = []
          this.formInputData = []
          this.dateRange = false
          this.appId = false
        }
        this.unitLoad = false
        this.$emit('LOAD_DATA', {
          type: 2,
          data: response,
          searchData: this.returnData()
        })
      })
    },
    checkDateRange (searchField) {
      const dateField = searchField.find(item => item.field_id === 'date')
      if (typeof dateField !== 'undefined') {
        this.dateRange = true
      }
      const appIdField = searchField.find(item => item.field_id === 'app')
      if (typeof appIdField !== 'undefined') {
        this.appId = true
      }
    },
    setFieldData (resultData) {
      const laArray = []
      const daArray = []
        resultData.forEach((item) => {
          const layJson = JSON.parse(Object.assign(item.layout))
          const dataJson = Object.assign(JSON.parse(item.data), { tab_name: item.tab.tab_name, field_id: item.id })
          laArray.push(layJson)
          daArray.push(dataJson)
        })
      this.layout = laArray
      this.formInputData = daArray
    },
    getServiceList (orgId) {
      const tmpService = this.$store.state.licenseRegistration.commonObj.serviceSteps.filter(item => item.org_id === orgId)
      return tmpService.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.service_id, text: item.service_name_bn }
        } else {
          return { value: item.service_id, text: item.service_name }
        }
      })
    },
    returnData () {
      let searchField = []
      if (this.formInputData.length) {
        const dynamicField = this.formInputData.map(item => {
          if (item.value === undefined) {
            return { field_name: item.field_name, tab_name: item.tab_name, value: '' }
          } else {
            return { field_name: item.field_name, tab_name: item.tab_name, value: item.value }
          }
        })
        searchField = searchField = this.setSearchField(dynamicField)
      } else {
        searchField = this.setSearchField(0)
      }
      return searchField
    },
    setSearchField (dynamicField) {
      return Object.assign(this.gridSearch,
          {
            tabList: this.getTabList(),
            dynamic_field: dynamicField,
            service_name: this.getServiceName(this.gridSearch.service_id),
            grid_step_id: this.getGridStep(this.gridSearch.service_id).step_id,
            grid_step_name: wordsToSnake(this.getGridStep(this.gridSearch.service_id).step_name),
            commonData: this.commonData
          })
    },
    searchSubmit () {
      const data = this.returnData()
      this.$emit('LOAD_DATA', {
          type: 1,
          data: data
        })
    },
    getServiceName () {
      const tmpService = this.$store.state.licenseRegistration.commonObj.serviceNamesList.find(item => item.value === this.gridSearch.service_id)
      if (typeof tmpService === 'undefined') {
        return ''
      } else {
        return wordsToSnake(tmpService.text_en)
      }
    },
    setValueInData (data) {
      if (data.type === 1) {
        this.gridSearch.from_date = data.value
      }
      if (data.type === 2) {
        this.gridSearch.to_date = data.value
      }
      if (data.type === 3) {
        this.gridSearch.application_id = data.value
      }
    },
    checkSingleService () {
        this.gridSearch.org_id = this.menuInfoList.org_id
        this.gridSearch.service_id = this.menuInfoList.service_id
        this.loadSearchForm(this.menuInfoList.service_id)
        this.is_disable = true
    },
    getTabList () {
      const formId = this.getGridStep(this.gridSearch.service_id).form_id
      const form = this.$store.state.licenseRegistration.commonObj.dynamicFormList.find(item => item.value === formId)
      if (typeof form === 'undefined') {
        return []
      }
      return form.tabs
    },
    getGridStep (serviceId) {
      const tmpService = this.$store.state.licenseRegistration.commonObj.serviceStepForm.find(item => (item.service_id === serviceId && item.form_type === 2))
      if (typeof tmpService === 'undefined') {
        return {}
      } else {
        return tmpService
      }
    },
    updateDropdown: function () {
      this.componentKey += 1
    }
  }
}
</script>
